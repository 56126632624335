///
/// Forty by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Header */

	@include keyframes('reveal-header') {
		0% { top: 0; opacity: 0; }
		100% { top: 0; opacity: 1; }
	}

	#header {
		@include vendor('display', 'flex');
		background-color: _palette(bg-alt);
		box-shadow: 0 0 0.25em 0 rgba(0,0,0,0.15);
		cursor: default;
		font-weight: _font(weight-bold);
		height: 3.25em;
		left: 0;
		letter-spacing: _font(letter-spacing-alt);
		line-height: 3.25em;
		margin: 0;
		position: fixed;
		text-transform: uppercase;
		top: 0;
		width: 100%;
		z-index: _misc(z-index-base);

		.logo {
			border: 0;
			display: inline-block;
			font-size: 0.8em;
			height: inherit;
			line-height: inherit;
			padding: 0 1.5em;

			strong {
				@include vendor('transition', (
					'background-color #{_duration(transition)} ease-in-out',
					'color #{_duration(transition)} ease-in-out'
				));
				background-color: _palette(fg-bold);
				color: _palette(bg);
				display: inline-block;
				line-height: 1.65em;
				margin-right: 0.325em;
				padding: 0 0.125em 0 (_font(letter-spacing-alt) + 0.125em);
			}

			&:hover {
				strong {
					background-color: _palette(highlight);
				}
			}

			&:active {
				strong {
					background-color: desaturate(darken(_palette(highlight), 15), 5);
				}
			}
		}

		nav {
			@include vendor('display', 'flex');
			@include vendor('justify-content', 'flex-end');
			@include vendor('flex-grow', '1');
			height: inherit;
			line-height: inherit;

			a {
				border: 0;
				display: block;
				font-size: 0.8em;
				height: inherit;
				line-height: inherit;
				padding: 0 0.75em;
				position: relative;
				vertical-align: middle;

				&:last-child {
					padding-right: 1.5em;
				}

				&[href="#menu"] {
					padding-right: 3.325em !important;

					&:before, &:after {
						background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32" preserveAspectRatio="none"><style>line { stroke-width: 2px; stroke: #{_palette(fg-bold)}; }</style><line x1="0" y1="11" x2="24" y2="11" /><line x1="0" y1="21" x2="24" y2="21" /><line x1="0" y1="16" x2="24" y2="16" /></svg>');
						background-position: center;
						background-repeat: no-repeat;
						background-size: 24px 32px;
						content: '';
						display: block;
						height: 100%;
						position: absolute;
						right: 1.5em;
						top: 0;
						vertical-align: middle;
						width: 24px;
					}

					&:after {
						@include vendor('transition', 'opacity #{_duration(transition)} ease-in-out');
						background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32" preserveAspectRatio="none"><style>line { stroke-width: 2px; stroke: #{_palette(highlight)}; }</style><line x1="0" y1="11" x2="24" y2="11" /><line x1="0" y1="21" x2="24" y2="21" /><line x1="0" y1="16" x2="24" y2="16" /></svg>');
						opacity: 0;
						z-index: 1;
					}

					&:hover, &:active {
						&:after {
							opacity: 1;
						}
					}

					&:last-child {
						padding-right: 3.875em !important;

						&:before, &:after {
							right: 2em;
						}
					}
				}
			}
		}

		&.reveal {
			@include vendor('animation', 'none');
		}

		&.alt {
			@include vendor('transition', 'none');
			@include vendor('animation', 'none');
			background-color: transparent;
			box-shadow: none;
			position: absolute;

			&.style1 { .logo { strong { color: _palette(accent1); } } }
			&.style2 { .logo { strong { color: _palette(accent2); } } }
			&.style3 { .logo { strong { color: _palette(accent3); } } }
			&.style4 { .logo { strong { color: _palette(accent4); } } }
			&.style5 { .logo { strong { color: _palette(accent5); } } }
			&.style6 { .logo { strong { color: _palette(accent6); } } }
		}

		@include breakpoint(xlarge) {
			nav {
				a {
					&[href="#menu"] {
						padding-right: 3.75em !important;

						&:last-child {
							padding-right: 4.25em !important;
						}
					}
				}
			}
		}

		@include breakpoint(large) {
			nav {
				a {
					&[href="#menu"] {
						padding-right: 4em !important;

						&:last-child {
							padding-right: 4.5em !important;
						}
					}
				}
			}
		}

		@include breakpoint(small) {
			height: 2.75em;
			line-height: 2.75em;

			.logo {
				padding: 0 1em;
			}

			nav {
				a {
					padding: 0 0.5em;

					&:last-child {
						padding-right: 1em;
					}

					&[href="#menu"] {
						padding-right: 3.25em !important;

						&:before, &:after {
							right: 0.75em;
						}

						&:last-child {
							padding-right: 4em !important;

							&:before, &:after {
								right: 1.5em;
							}
						}
					}
				}
			}
		}

		@include breakpoint(xsmall) {
			nav {
				a {
					&[href="#menu"] {
						overflow: hidden;
						padding-right: 0 !important;
						text-indent: 5em;
						white-space: nowrap;
						width: 5em;

						&:before, &:after {
							right: 0;
							width: inherit;
						}

						&:last-child {
							&:before, &:after {
								width: 4em;
								right: 0;
							}
						}
					}
				}
			}
		}
	}
